/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 * - /npm/bootstrap@4.1.3/dist/js/bootstrap.bundle.min.js
 * - /npm/swiper@4.3.3/dist/js/swiper.min.js
 * - /npm/jquery.scrollto@2.1.2/jquery.scrollTo.min.js
 * - /npm/vanilla-lazyload@17.4.0/dist/lazyload.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
